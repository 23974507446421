<template>
  <!--begin::Legal Privacy EN-->
  <div class="p-6">
    <div class="text-center h1-primary mt-3 mb-6">
      LOGIOK Privacy Policy
      <div class="tertiary-rectangle" style="margin: 0.25em auto;"></div>
    </div>

    <div class="paragraph-primary">
      Desde LOGIOK 4 PUNTO 0, S.L., en adelante LOGIOK estamos comprometidos con 
      un tratamiento responsable de sus datos y en cumplimiento del Reglamento (UE) del 
      Parlamento Europeo y del Consejo 679/2016, de 27 de abril (GDPR) relativo a la 
      protección de las personas físicas en lo que respecta al tratamiento de los datos 
      personales y a la libre circulación de estos datos y la Ley orgánica 3/2018, de 5 de 
      diciembre (LOPDGDD), de protección de datos personales y garantía de derechos 
      digitales procede a facilitar la siguiente información:
    </div>

    <div class="text-center h3-primary mt-9 mb-6">
      ¿Quién es el responsable del tratamiento de sus datos?
    </div>

    <div class="paragraph-primary">
      La mercantil LOGIOK es el responsable del tratamiento de los datos personales de los usuarios de la web, 
      siendo su dirección de correo 
      <a href="mailto:info@logiok.es"><strong><u>info@logiok.es</u></strong></a> 
      y situada en 
      <strong>Rambla Catalunya, 124 - PLANTA 3 PUERTA 1, Barcelona, 08008, Barcelona.</strong>
    </div>
      
    <div class="text-center h3-primary mt-12 mb-6">
      <u>TRATAMIENTO DE DATOS PERSONALES DERIVADOS DE LA NAVEGACIÓN EN LA WEB y UTILIZACIÓN APP</u>
    </div>
    
    <div class="paragraph-primary">
      <strong>Finalidad del tratamiento de datos</strong>
    </div>
    
    <div class="paragraph-primary">
      Análisis de la usabilidad y de la calidad para la mejora de nuestro servicio 
      realizando análisis y estudios estadísticos.
    </div>

    <div class="paragraph-primary mt-6">
      <strong>Legitimación para el tratamiento de datos</strong>
    </div>
    
    <div class="paragraph-primary">
      El tratamiento es necesario para la satisfacción de intereses legítimos 
      perseguidos por el responsable del tratamiento o por un tercero, 
      siempre que dichos intereses no prevalezcan sobre los intereses o los derechos y libertades fundamentales 
      del interesado que requieran la protección de datos personales. 
      Respecto de la aplicación de cookies que no sean estrictamente necesarias 
      la legitimación del tratamiento será la prestación del consentimiento por parte del usuario 
      de nuestra 
      <router-link to="/legal/cookies"><strong>política de cookies</strong></router-link>.
    </div>
      
    <div class="paragraph-primary mt-6">
      <strong>Tiempo de conservación de sus datos</strong>
    </div>
    
    <div class="paragraph-primary">
      En cuanto a los datos de navegación, el tiempo de conservación de sus datos 
      viene establecido en nuestra 
      <router-link to="/legal/cookies"><strong>política de cookies</strong></router-link>.
    </div>
      
    <div class="paragraph-primary mt-6">
      <strong>Destinatarios de la comunicación de sus datos</strong>
    </div>
    
    <div class="paragraph-primary">
      Los datos derivados del contacto y la consulta no serán comunicados a terceros excepto cuando exista obligación legal aplicable.
    </div>
      
    <div class="text-center h3-primary mt-12 mb-6">
      <u>TRATAMIENTO DE DATOS PERSONALES DERIVADOS DE CONSULTAS Y OTRAS PETICIONES (SOPORTE TÉCNICO)</u>
    </div>
    
    <div class="paragraph-primary mt-6">
      <strong>Finalidad del Tratamiento de Datos</strong>
    </div>
    
    <div class="paragraph-primary">
      El tratamiento de sus datos personales perseguirá el fin de gestionar el contacto que ha realizado 
      o la consulta que nos ha remitido para mantener una relación comercial. 
      De forma complementaria, se procederá al envío de publicaciones comerciales 
      sobre nuestros productos y servicios 
      en caso de que nos manifieste su consentimiento añadido para tal fin.
    </div>
    
    <div class="paragraph-primary mt-6">
      <strong>Legitimación para el tratamiento de datos</strong>
    </div>
    
    <div class="paragraph-primary">
      Nos legitima para el tratamiento de sus datos personales, 
      a través del consentimiento que presta enviando el formulario de contacto o la consulta. 
      Este consentimiento prestado deberá corresponder en todo momento a mayores de edad.
    </div>
    
    <div class="paragraph-primary mt-6">
      <strong>Tiempo de conservación de sus datos</strong>
    </div>
    
    <div class="paragraph-primary">
      Se conservarán mientras exista un interés mutuo para mantener el fin del tratamiento 
      y cuando ya no sea necesario para tal fin, se suprimirán con medidas de seguridad adecuadas 
      para garantizar la seudonimización de los datos o la destrucción total de los mismos. 
      Le recordamos que puede revocar el consentimiento dado en el momento en que usted lo desee, 
      para ello solicítenoslo a través de la dirección 
      <a href="mailto:info@logiok.es"><strong><u>info@logiok.es</u></strong></a> 
    </div>
      
    <div class="paragraph-primary mt-6">
      <strong>Destinatarios de la comunicación de sus datos</strong>
    </div>
    
    <div class="paragraph-primary">
      Los datos derivados del contacto y la consulta no serán comunicados a terceros 
      excepto cuando exista obligación legal aplicable.    
    </div>
      
    <div class="text-center h3-primary mt-12 mb-6">
      <u>TRATAMIENTO DE DATOS PERSONALES DERIVADOS DEL SERVICIO DE INTERMEDIACIÓN</u>
    </div>
    
    <div class="paragraph-primary mt-6">
      <strong>Finalidad del Tratamiento de Datos</strong>
    </div>
    
    <div class="paragraph-primary">
      El tratamiento de sus datos personales perseguirá el fin de prestar el servicio de intermediación 
      en la entrega de bienes y prestaciones de servicios que aparecen publicitados en nuestra aplicación 
      y la relación con nuestros proveedores. 
      Complementariamente, será finalidad del tratamiento el envío de publicaciones comerciales 
      y boletines informativos sobre novedades, ofertas y promociones de nuestra actividad a nuestros clientes.
    </div>
    
    <div class="paragraph-primary mt-6">
      <strong>Legitimación para el tratamiento de datos</strong>
    </div>
    
    <div class="paragraph-primary">
      Nos legitima para el tratamiento de sus datos personales, 
      la ejecución de un contrato en el que el interesado es parte 
      o para la aplicación a petición de éste de medidas precontractuales.
    </div>
    
    <div class="paragraph-primary mt-6">
      <strong>Tiempo de conservación de sus datos</strong>
    </div>
    
    <div class="paragraph-primary">
      Los datos personales proporcionados se conservarán mientras se lleve a cabo 
      la prestación del servicio contratado y posteriormente durante el plazo de tiempo 
      necesario hasta la prescripción de las obligaciones aplicables tanto al servicio prestado 
      como a su facturación.
    </div>
      
    <div class="paragraph-primary mt-6">
      <strong>Destinatarios de la comunicación de sus datos</strong>
    </div>
    
    <div class="paragraph-primary">
      Los datos derivados de entrega de bienes y prestaciones de servicios serán comunicados 
      a las entidades bancarias que ofrecen sus pasarelas bancarias de pago para permitir un pago seguro. 
      No vamos a tratar sus datos económicos para realizar los pagos del servicio, 
      usted será trasladado a una pasarela segura de pago para llevar los mismos a cabo. 
      Por lo demás sus datos no serán objeto de cesión salvo obligación legal aplicable.
    </div>
      
    <div class="text-center h3-primary mt-12 mb-6">
      <u>DERECHOS DEL USUARIO</u>
    </div>
    
    <div class="paragraph-primary">
      Cualquier persona tiene derecho a obtener confirmación sobre si tratamos datos personales 
      que le conciernen o no. Las personas interesadas tienen derecho a acceder a sus datos personales 
      y a obtener una copia de los datos personales objeto del tratamiento 
      <strong>(Derecho de acceso)</strong>, 
      a actualizarlos, así como a solicitar la rectificación de los datos inexactos 
      <strong>(Derechos de rectificación)</strong> 
      o, en su caso, solicitar la supresión cuando, entre otros motivos, 
      los datos ya no sean necesarios para los fines para los que fueron recogidos 
      <strong>(Derechos de supresión)</strong>. 
      Los interesados podrán solicitar la limitación del tratamiento de sus datos, 
      en su caso, únicamente los conservaremos para el ejercicio o la defensa de reclamaciones 
      <strong>(Derecho a la limitación del tratamiento)</strong>.
    </div>
    
    <div class="paragraph-primary mt-6">
      Como consecuencia de la aplicación del derecho a la supresión u oposición 
      al tratamiento de datos personales en el entorno on-line los interesados tienen el 
      <strong>Derecho al olvido</strong> según la jurisprudencia del Tribunal de Justicia de la UE. 
      Los interesados podrán oponerse al tratamiento de sus datos con fines de mercadotecnia, 
      incluida la elaboración de perfiles.   
    </div>
    
    <div class="paragraph-primary mt-6">
      Con el fin de facilitar el ejercicio de sus derechos, a través de un correo electrónico dirigido a 
      <a href="mailto:info@logiok.es"><strong><u>info@logiok.es</u></strong></a> 
      daremos respuesta al ejercicio de sus derechos o solicitudes de información.
    </div>
      
    <div class="paragraph-primary mt-6">
      Si usted considera que sus derechos no se han atendido debidamente, 
      tiene derecho a presentar una reclamación ante la autoridad competente, 
      la Agencia Española de Protección de Datos
      <a href="https://www.aepd.es" target="_blank"><strong><u>www.aepd.es</u></strong></a>  
    </div>
  </div>
  <!--end::Legal Privacy EN-->
</template>

<script>
export default {
  name: "LegalPrivacyEN"
};
</script>
