<template>
  <!--begin::Legal Privacy-->
  <div>
    <LegalPrivacyEN v-if="UserLang === 'en'" />
    <LegalPrivacyES v-else />
  </div>
  <!--end::Legal Privacy-->
</template>

<script>
import i18nService from "@/core/services/i18n.service.js";

import LegalPrivacyEN from "@/view/pages/landing/legal/privacy/Content_en.vue";
import LegalPrivacyES from "@/view/pages/landing/legal/privacy/Content_es.vue";

export default {
  name: "LegalPrivacy",

  components: {
    LegalPrivacyEN,
    LegalPrivacyES
  },

  data() {
    return {
      UserLang: i18nService.getActiveLanguage()
    };
  },

  mounted() {
    this.$nextTick(() => {
      document.title = this.$t("HEADER.TITLE");
    });
  }
};
</script>